import React, { Component } from "react";

import { css } from "@emotion/react";

import * as Atoms from "../../components/atoms";

import * as Modules from "../../components/modules";
import * as Common from "../../common";
import * as Libs from "libs";
class Footer extends Component {
	//--------------------------------------
	//  Style
	//--------------------------------------
	containerCss = () => {
		//background-color: #f0F;
		return css`
			background-color: #fff;
			height: auto;
			width: 100vw;

			position: relative;
			z-index: 101;
			.on-story & {
				display: none;
			}
		`;
	};
	mailChimpCss = () => {
		return css`
			${Libs.Common.Func.getPcSpVwValue("margin-top", 100, 70)};
		`;
	};
	SnsListCss = (props) =>
		css`
			${Libs.Common.Func.getPcSpVwValue("margin-top", 170, 100)};
			text-align: center;
		`;
	howltLogoCss = () => {
		return css`
			${Libs.Common.Func.getPcSpVwValue("margin-top", 60, 160)};
			${Libs.Common.Func.getPcSpVwValue("padding-top", 50, 50)};
			border-top: 1px dotted #ccc;
			${Libs.Styles.Mq.moreTab} {
				width: 88vw;
				margin-left: auto;
				margin-right: auto;
				svg {
					width: 100px;
					display: block;
				}
			}
			${Libs.Styles.Mq.large} {
				width: 80vw;
				margin-left: auto;
				margin-right: auto;
				svg {
					width: 100px;
					display: block;
				}
			}
			${Libs.Styles.Mq.lessPab} {
				padding-left: 10vw;
				svg {
					width: 80px;
				}
			}
		`;
	};
	hcLogoCss = () => {
		return css`
			${Libs.Common.Func.getPcSpVwValue("margin-top", 100, 70)};
			${Libs.Common.Func.getPcSpVwValue("padding-top", 50, 50)};
			border-top: 1px dotted #ccc;
			${Libs.Styles.Mq.moreTab} {
				width: 88vw;
				margin-left: auto;
				margin-right: auto;
				svg {
					width: 180px;
				}
			}
			${Libs.Styles.Mq.large} {
				width: 80vw;
				margin-left: auto;
				margin-right: auto;
				svg {
					width: 180px;
				}
			}
			${Libs.Styles.Mq.lessPab} {
				svg {
					width: 180px;
				}
				padding-left: 10vw;
			}
		`;
	};
	descItemCont = () => css`
		${Libs.Styles.Mq.moreTab} {
			width: 40%;
			${Libs.Styles.Position.ChildSpacing("50px")}
		}
		${Libs.Styles.Mq.lessPab} {
			${Libs.Styles.Position.ChildSpacing("30px")}
			margin-top: 30px;
		}
	`;

	subNavContCss = () => {
		return css`
			${Libs.Common.Func.getPcSpVwValue("margin-top", 30, 50)};
			opacity: 1;

			${Libs.Styles.Mq.moreTab} {
				${Libs.Styles.Flex.flexLine({ between: true })}
				width: 88vw;
				margin-left: auto;
				margin-right: auto;
			}
			${Libs.Styles.Mq.large} {
				${Libs.Styles.Flex.flexLine({ between: true })}
				width: 80vw;
				margin-left: auto;
				margin-right: auto;
			}
			${Libs.Styles.Mq.lessPab} {
				${Libs.Styles.Position.ChildSpacing("40px")}
				padding-left: 10vw;
			}
		`;
	};

	copyContCss = () => css`
		${Libs.Common.Func.getPcSpVwValue("margin-top", 120, 100)};
		width: 100%;
		height: 60px;
		position: relative;
		bottom: 0px;
		background-color: #000;
	`;
	copyCss = () => css`
		display: flex;
		padding-top: 18px;
		margin-left: 5vw;
		font-size: 12px;
		color: #fff;
		> li {
			margin-right: 5vw;
		}
		${Libs.Styles.Mq.lessPab} {
			width: 90vw;
			margin-left: 5%;
		}
	`;
	langCss = () => css`
		position: absolute;
		right: 5vw;
		top: 15px;
	`;
	//--------------------------------------
	//  Scripts
	//--------------------------------------
	constructor(props) {
		super(props);
		this.container = React.createRef();
		this.mount = false;
		const { dispatch } = this.props;
		this.dispatch = dispatch;
		this.langKey = Libs.Common.Func.getLangKey();
	}
	mount = false;
	componentDidMount() {
		this.mount = true;
	}

	render() {
		this.navItemRefs = [];
		const navItems = Common.Config.getNavItems();
		const langKey = Libs.Common.Func.getLangKey();
		const h3Style = {
			fontPc: {
				fontSize: 14,
				lineHeight: 14,
			},
			fontSp: {
				fontSize: 14,
				lineHeight: 14,
			},
			positionPc: { space: "0 0 7 0" },
			positionSp: { space: "0 0 15 0" },
			whiteSpace: "nowrwap",
		};
		const pStyle = {
			fontPc: {
				fontFace: "sansserif",
				fontSize: 25,
				lineHeight: 30,
				letterSpacing: -0.4,
			},
			fontSp: {
				fontFace: "sansserif",
				fontSize: 25,
				lineHeight: 30,
				letterSpacing: -0.4,
			},
		};

		return (
			<>
				<Modules.MailChimpForm css={this.mailChimpCss} />
				{/* <Modules.SendinblueForm />*/}
				<nav css={this.containerCss} ref={this.container} className="footer">
					<h2 css={this.howltLogoCss}>
						<Atoms.LogoHowlt />
					</h2>

					<div
						css={this.subNavContCss}
						ref={(el) => {
							if (el) this.navItemRefs.push(el);
						}}
					>
						<div css={this.descItemCont}>
							<div>
								<Libs.Atoms.H3 styles={h3Style}>Site Map</Libs.Atoms.H3>
								<ul>
									{navItems.map((item, i) => (
										<li key={`navItem${i}`}>
											<Libs.Atoms.Span data-hover={item.label} styles={pStyle}>
												<Libs.Atoms.TriggerLink
													transition={
														Libs.Transition.TrigerPageTransition
															.TrigerPageTransition
													}
													to={
														item.to !== "/visit"
															? `/${langKey}${item.to}`
															: `${item.to}`
													}
												>
													{item.label}
												</Libs.Atoms.TriggerLink>
											</Libs.Atoms.Span>
										</li>
									))}
									<li
										css={css`
											margin-top: 14px;
										`}
									>
										<Libs.Atoms.Span
											data-hover={"shopping-info"}
											styles={{
												fontPc: {
													fontFace: "sansserif",
													fontSize: 20,
													lineHeight: 20,
													letterSpacing: -0.4,
												},
												fontSp: {
													fontFace: "sansserif",
													fontSize: 20,
													lineHeight: 20,
													letterSpacing: -0.4,
												},
											}}
										>
											<Libs.Atoms.TriggerLink
												transition={
													Libs.Transition.TrigerPageTransition
														.TrigerPageTransition
												}
												to={`/${langKey}/shopping-info`}
											>
												Shopping Info
											</Libs.Atoms.TriggerLink>
										</Libs.Atoms.Span>
									</li>
									<li>
										<Libs.Atoms.Span
											data-hover={"privacy-policy"}
											styles={{
												fontPc: {
													fontFace: "sansserif",
													fontSize: 20,
													lineHeight: 20,
													letterSpacing: -0.4,
												},
												fontSp: {
													fontFace: "sansserif",
													fontSize: 20,
													lineHeight: 20,
													letterSpacing: -0.4,
												},
											}}
										>
											<Libs.Atoms.TriggerLink
												transition={
													Libs.Transition.TrigerPageTransition
														.TrigerPageTransition
												}
												to={`/${langKey}/privacy-policy`}
											>
												Privacy Policy
											</Libs.Atoms.TriggerLink>
										</Libs.Atoms.Span>
									</li>
								</ul>
							</div>
						</div>
						<Atoms.InfoAbout></Atoms.InfoAbout>
						<Atoms.InfoContact></Atoms.InfoContact>
					</div>

					<h2 css={this.hcLogoCss}>
						<Atoms.Logo02 />
					</h2>
					<div
						css={this.subNavContCss}
						ref={(el) => {
							if (el) this.navItemRefs.push(el);
						}}
					>
						<Atoms.InfoHour></Atoms.InfoHour>

						<Atoms.InfoAboutHc></Atoms.InfoAboutHc>
						<div
							css={css`
								${Libs.Styles.Mq.moreTab} {
									width: 20%;
								}
							`}
						>
							<Libs.Atoms.H3 styles={h3Style}>Connect with Us</Libs.Atoms.H3>
							<a href="https://howlt-coffee.com" target="_new">
								<Libs.Atoms.Span styles={pStyle}>
									howlt-coffee.com
								</Libs.Atoms.Span>
							</a>
							<Modules.SnsListHc
								styles={{
									width_pc: 11,
									width_sp: 40,
									positionPc: { space: "7 0 0 0" },
									positionSp: { space: "7 0 0 0" },
								}}
							></Modules.SnsListHc>
						</div>
					</div>

					<div css={this.copyContCss}>
						<ul css={this.copyCss}>
							<li>
								<a href="https://loworks.co.jp" target="_new">
									<Libs.Atoms.Span
										styles={{
											fontPc: {
												fontSize: 14,
												fontFace: "sansserif",
												lineHeight: 14,
												letterSpacing: 0.5,
												color: "#fff",
											},

											fontSp: {
												fontSize: 14,
												fontFace: "sansserif",
												lineHeight: 14,
												letterSpacing: 0.5,
												color: "#fff",
											},
										}}
									>
										Site by LOWORKS Inc.
									</Libs.Atoms.Span>
								</a>
							</li>
						</ul>
						<div css={this.langCss}>
							<Libs.Atoms.Span
								styles={{
									fontPc: {
										fontSize: 14,
										letterSpacing: 0.5,
										fontFace: "sansserif",
										color: "#fff",
									},
									fontSp: {
										fontSize: 14,
										letterSpacing: 0.5,
										fontFace: "sansserif",
										color: "#fff",
									},
								}}
							>
								©2021 Howlt . BK . NY
							</Libs.Atoms.Span>
						</div>
					</div>
				</nav>
			</>
		);
	}
}
export default Footer;
